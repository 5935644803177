/* eslint-disable react/jsx-no-bind */
/* eslint-disable indent */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useI18next } from 'gatsby-plugin-react-i18next';
// UI lib components
import { Container, Visible } from 'react-grid-system';

// UI local components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import BeardCrumbs from '../page-components/breadCrumbs';
import BannerSection from '../page-components/page-solutions/banner';

// Assets
import BANNER_IMG from '../images/detail-reference/referenceBG.png';

// Styles
import '../page-styles/details-reference.scss';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { API } from '../shared/API';

/* -------------------------------------------------------------------------- */
/*                          All References Page                               */
/* -------------------------------------------------------------------------- */

function DetailsReferencePage() {
  /* ********************************** HOOKS ********************************* */
  const [singleReference, setSingleReference] = useState({});
  const [solutions, setSolutions] = useState([]);

  /* -------------------------------- CONST --------------------------------- */
  const SITE_TITLE = 'LUXOR TECHNOLOGY AND TRADE | Reference details';
  // eslint-disable-next-line operator-linebreak
  const SITE_DESCRIPTION =
    // eslint-disable-next-line max-len
    'Luxor technolgie & Trade est une société Tunisienne Spécialisée dans le développement des solutions techniques pour la construction. ';

  const IS_BROWSER = typeof window !== 'undefined';
  const { language } = useI18next();
  useEffect(() => {
    if (IS_BROWSER) {
      const GET_SINGLE_REFERENCE = JSON.parse(
        localStorage.getItem('singleReference'),
      );
      setSingleReference(GET_SINGLE_REFERENCE);
      axios
        .get(API(language === 'fr' ? 'reference' : 'referenceen'))
        .then(({ data: { data } }) => {
          if (IS_BROWSER) {
            localStorage.setItem('references-array', JSON.stringify(data));
            const REFERENCES_STORAGE = localStorage.getItem('references-array');
            if (REFERENCES_STORAGE) {
              const referencesTab = JSON.parse(REFERENCES_STORAGE);
              if (!GET_SINGLE_REFERENCE) {
                setSingleReference(referencesTab[0]);
              }
            }
          }
        })
        .catch((error) => error);
    }
  }, [IS_BROWSER, language]);

  useEffect(() => {
    axios.get(API('solution')).then(({ data: { data } }) => {
      setSolutions(data);
    });
  }, []);

  /* ---------------------------------- RENDERING HELPERS --------------------------------- */
  function getUsedProducts() {
    const GET_USED_PRODUCTS = singleReference?.products_used;
    const GET_USED_PRODUCTS_TITLE =
      singleReference?.products_used.split(':')[0];
    const GET_USED_PRODUCTS_CONTENT =
      singleReference?.products_used.split(':')[1];
    function getLastWord() {
      const word = GET_USED_PRODUCTS?.split(' ');
      return word[word?.length - 1];
    }

    const USED_PRODUCTS = getLastWord().replaceAll('\n\n', '');
    const FIND_SOLUTION = solutions.find(({ title }) => {
      const test =
        GET_USED_PRODUCTS?.split('-')[1]?.includes(title) ||
        GET_USED_PRODUCTS?.split(' ')?.includes(title.replaceAll(' ', '')) ||
        USED_PRODUCTS === title.replaceAll(' ', '') ||
        GET_USED_PRODUCTS?.includes(title);
      return test;
    });

    if (FIND_SOLUTION) {
      const GET_SOLUTION_PATH_LVL1 = FIND_SOLUTION.level_1?.data.slug;
      const GET_SOLUTION_PATH_LVL2 = FIND_SOLUTION.level_2?.data.slug;
      const GET_SOLUTION_PATH_LVL3 = FIND_SOLUTION.level_3?.data.slug;

      return (
        <Link
          to={`/solutions/${GET_SOLUTION_PATH_LVL1}/${GET_SOLUTION_PATH_LVL2}/${GET_SOLUTION_PATH_LVL3}`}
        >
          <span className="used-poducts-title">
            {`${GET_USED_PRODUCTS_TITLE}`}
            :
          </span>
          {`${GET_USED_PRODUCTS_CONTENT}`}
        </Link>
      );
    }

    return (
      <h3>
        <span className="used-poducts-title">
          {GET_USED_PRODUCTS_TITLE}
          :
        </span>
        {GET_USED_PRODUCTS_CONTENT}
      </h3>
    );
  }
  //  localization
  const { t } = useTranslation();
  const {
    title,
    title1,
    title2,
    subTitle1,
    subTitle2,
    subTitle3,
    subTitle4,
    subTitle5,
    subTitle6,
    subTitle7,
    subTitle8,
    subTitle9,
    subTitle10,
    subTitle11,
  } = t('detailsReferences', { returnObjects: true });

  const { titleBreadcrumbs, pageLink } = t('reference', {
    returnObjects: true,
  });
  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title={SITE_TITLE} description={SITE_DESCRIPTION} />
      <BannerSection bannerImage={BANNER_IMG}>
        <h1>{title}</h1>
      </BannerSection>
      <Visible lg xl xxl>
        <Container className="" fluid>
          <BeardCrumbs
            isDetailed
            path={pageLink}
            titleBreadcrumbs={titleBreadcrumbs}
          />
        </Container>
      </Visible>
      <Container className="details-reference-container max-w top">
        <Container className="fluid">
          <img
            src={`https://admin.luxor.com.tn${singleReference?.main_picture?.data.url}`}
            alt={singleReference?.project_name}
          />
          <Container className="flex flex-column fluid" fluid>
            <h1>{title1}</h1>
            {singleReference?.project_name &&
              singleReference?.project_name !== '-' && (
                <h3>
                  <span>{subTitle1}</span>
                  {singleReference?.project_name}
                </h3>
              )}

            {singleReference?.details && singleReference?.details !== '-' && (
              <h3>
                <span>{subTitle2}</span>
                {singleReference?.details}
              </h3>
            )}

            {singleReference?.mission && singleReference?.mission !== '-' && (
              <h3>
                <span>{subTitle3}</span>
                {singleReference?.mission}
              </h3>
            )}
            {singleReference?.year && singleReference?.year !== '-' && (
              <h3>
                <span>{subTitle4}</span>
                {singleReference?.year}
              </h3>
            )}

            {singleReference?.products_used && (
              <>
                <h1>{subTitle5}</h1>
                {getUsedProducts()}
              </>
            )}

            <h1>{title2}</h1>
            {singleReference?.master_of_work &&
              singleReference?.master_of_work !== '-' && (
                <h3>
                  <span>{subTitle6}</span>
                  {singleReference?.master_of_work}
                </h3>
              )}
            {singleReference?.design_office &&
              singleReference?.design_office !== '-' && (
                <h3>
                  <span>{subTitle7}</span>
                  {singleReference?.design_office}
                </h3>
              )}
            {singleReference?.control_office &&
              singleReference?.control_office !== '-' && (
                <h3>
                  <span>{subTitle8}</span>
                  {singleReference?.control_office}
                </h3>
              )}
            {singleReference?.consulting_office &&
              singleReference?.consulting_office !== '-' && (
                <h3>
                  <span>{subTitle9}</span>
                  {singleReference?.consulting_office}
                </h3>
              )}
            {singleReference?.company && singleReference?.company !== '-' && (
              <h3>
                <span>{subTitle10}</span>
                {singleReference?.company}
              </h3>
            )}
            {singleReference?.concrete_plant &&
              singleReference?.concrete_plant !== '-' && (
                <h3>
                  <span>{subTitle11}</span>
                  {singleReference?.concrete_plant}
                </h3>
              )}
          </Container>
        </Container>
      </Container>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["Reference", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default DetailsReferencePage;
